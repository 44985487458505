$ const { link = true, topics } = input;

style.scss {
  .ht-event-topics {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      flex: 0 0 auto;
    }
    &__tag {
      display: block;
      padding: 0.25rem 0.5rem;
      border: 1px solid var(--topic-color);
      color: var(--topic-color);

      &--link:hover {
        border-color: var(--color-primary-darker);
        background-color: var(--color-primary-a20);
        color: var(--color-primary-darker);
        text-decoration: none;
      }
    }
  }
}

<ul class="ht-event-topics">
  <for|topic| of=topics>
    <li>
      <if(link)>
        <a
          class="ht-event-topics__tag ht-event-topics__tag--link"
          href=`/theme/${topic.toLowerCase()}`
        >
          ${topic}
        </a>
      </if>
      <else>
        <span class="ht-event-topics__tag">
          ${topic}
        </span>
      </else>
    </li>
  </for>
</ul>
